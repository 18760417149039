import { render, staticRenderFns } from "./MyCenter.vue?vue&type=template&id=3b61147a&scoped=true&"
import script from "./MyCenter.vue?vue&type=script&lang=js&"
export * from "./MyCenter.vue?vue&type=script&lang=js&"
import style0 from "./MyCenter.vue?vue&type=style&index=0&lang=scss&scope=true&"
import style1 from "./MyCenter.vue?vue&type=style&index=1&id=3b61147a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b61147a",
  null
  
)

export default component.exports