<template>
  <div v-if="$store.getters.getSiteInfo">
    <div>
      <div class="top_img-size">
        <img :src="adslist.localimg" alt @click="adsClick" />
      </div>
      <!-- 顶部栏 -->
      <div class="top_case">
        <div class="top_case-one">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span class="title-oblong"></span>
              <span class="title" style="font-weight: 600">我的账户</span>
            </div>
            <div class="text item">等&nbsp;&nbsp;&nbsp;&nbsp;级: <span style="color: red; margin-left:25px;font-size: 16px;">{{$store.getters.getUserInfo.group_text }}</span></div>
            <div class="text item">{{ '手机号:' + o + $store.getters.getUserInfo.username }}</div>
            <div class="text item">{{ '注' + '\u00a0\u00a0\u00a0' + '册:' + o + createtime }}</div>
            <!-- <a href="#" style="font-weight: 600; color: red" @click="recharge">[立即充值]</a> -->
            <div class="text item">
              {{ '余' + '\u00a0\u00a0\u00a0' + '额:' + o + balance }} <i class="el-icon-refresh" @click="refresh"></i><span style="position: absolute; margin-left: 15px; margin-top: -3px" class="paycode">
                <top-up />
              </span>
            </div>
            <div class="text item codeInfo">{{ '获得佣金:' + o + money }} &nbsp;&nbsp;<router-link tag="a" :to="'/account/balance'"><span style="color:#b58181">去提现</span></router-link>
            </div>
            <div style="cursor: pointer"></div>

          </el-card>
        </div>

        <div class="top_case-two">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span class="title-oblong"></span>
              <span class="title" style="font-weight: 600">专属客服</span>
            </div>
            <div class="text item">{{ '工 号:' + o + compute }}</div>
            <div class="text item">{{ '姓 名:' + o + comput }}</div>
            <div class="text item">{{ 'Q' + '\u00a0\u00a0' + 'Q:' + o + $store.getters.getSiteInfo.qq_service }}</div>
            <div class="text item">{{ '电 话:' + o + $store.getters.getSiteInfo.service_tel }}</div>
            <div class="top_case-code">
              <img class="img" :src="$store.getters.getSiteInfo.wechat_service" />
              <div class="img-text">请扫码,加微信</div>
            </div>
          </el-card>
        </div>

        <div class="top_case-three">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span class="title-oblong"></span>
              <span class="title" style="font-weight: 600">会员一次性充值到达等级 优享会员价格：请查看 &nbsp;<router-link :to="'/account/myprice'"><span style="color:red; cursor: pointer;">会员价格</span></router-link></span>
            </div>
            <el-row>
              <el-col :span="24">
                <div class="notice-item-one">
                  <div class="grid-content bg-purple-dark">
                    <div v-for="(item, index) in awardName" :key="index">
                      本站会员单次充值满金额<span>{{ item.top_up_amount }}</span>升级<span>{{ item.name }}</span>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>

          </el-card>
        </div>
      </div>
      <!-- 中间部分 -->
      <div class="center">
        <div class="liuc">
          <div class="items" @click="$router.push('/orderAdmin/purchase')">
            <div class="item_in">
              <img src="~assets/icons/fh.png" alt />
              <p>立即发货</p>
            </div>
          </div>

          <div class="items" @click="$router.push('/orderAdmin/orderList')">
            <div class="item_in">
              <img src="~assets/icons/dd.png" alt />
              <p>订单管理</p>
            </div>
          </div>

          <div class="items" @click="$router.push('/workOrder/myOrder')">
            <div class="item_in">
              <img src="~assets/icons/sh.png" alt />
              <p>售后管理</p>
            </div>
          </div>

          <div class="items" @click="$router.push('/workOrder/orderSumbit')">
            <div class="item_in">
              <img src="~assets/icons/didan.png" alt />
              <p>底单申请</p>
            </div>
          </div>
          <!-- <img src="../../../../public/pc/buy_step.png" alt=""> -->
        </div>

        <div class="center-right">
          <el-input v-model="url"></el-input>
          <div class="btn">
            <el-button type="primary" size="mini" v-clipboard:copy="url" v-clipboard:success="onCopy">复制网址,邀请好友注册</el-button>
            <el-button type="primary" size="mini" @click="change">更换邀请语</el-button>
          </div>
          <div class="text">
            <span v-if="people !== 0" style="color: gray">已成功邀请 <span style="color: #60abfd">{{ people }}人</span></span>
            <span v-show="people === 0" style="color: #60abfd">&nbsp;努力加油...还没有邀请来用户...复制链接即可马上去邀请...</span>
          </div>
        </div>
      </div>
      <!-- 底部 -->
      <div class="bottom">
        <div class="bottom-content">
          <div class="bottom-item">
            <div>下单总数</div>
            <div class="font">
              <span style="color: red">{{ packInfo.order_num }}</span>个
            </div>
          </div>
          <div class="bottom-item">
            <div>待支付</div>
            <div class="font">
              <span>{{ packInfo.first }}</span>笔
            </div>
          </div>
          <div class="bottom-item">
            <div>打单中</div>
            <div class="font">
              <span>{{ packInfo.second }}</span>笔
            </div>
          </div>
          <div class="bottom-item">
            <div>已出单</div>
            <div class="font">
              <span>{{ packInfo.third }}</span>笔
            </div>
          </div>
          <div class="bottom-item">
            <div>异常</div>
            <div class="font">
              <span>{{ packInfo.forth }}</span>笔
            </div>
          </div>
          <div class="bottom-item">
            <div>取消</div>
            <div class="font">
              <span>{{ packInfo.five }}</span>笔
            </div>
          </div>
          <div class="bottom-item">
            <div>总包裹数</div>
            <div class="font">
              <span>{{ packInfo.package_num }}</span>个
            </div>
          </div>
        </div>
      </div>
      <!-- 充值 -->
      <div class="recharge">
        <el-dialog :visible.sync="redialogVisible" :before-close="handleClose" width="60%">
          <div class="recharge-hint">
            <div>使用须知</div>
            <p>
              1.请使用支付宝扫描左侧收款码进行付款,付款前请核对收款人信息:<span style="color: red">&nbsp;{{ $store.getters.getSiteInfo.alipay_account }}</span>
            </p>
            <p>2.在付款页面备注一栏填写您的登录账号,可实现自动到账!如未备注账号,可从支付宝付款详情中手动复制20开头的订单号,手动提交订单号也可自动到账.</p>
            <p>3.平台暂不支持退款,请根据需要充值.</p>
          </div>
          <div class="recharge-title"><span class="recharge-title-tag" style="color: red">|</span> <span class="recharge-title-text" style="color: black">账户充值</span></div>
          <div class="recharge-main">
            <div class="recharge-main-img"><img :src="imgUrl" /></div>
            <div class="recharge-main-right">
              <div class="main-right-balance">
                <i class="el-icon-s-cooperation"></i><span>账户可用金额</span>
                <p>¥{{ balanceInfo.balance }}</p>
                <div class="main-right-freshen">
                  <el-button size="mini" plain @click="renovate" style="background-color: white; border-color: red; color: red; font-size: 14px">刷新查看余额</el-button>
                </div>
              </div>
              <el-input v-model="input" placeholder="请输入支付宝订单号"></el-input>
              <el-button style="width: 100%; margin-top: 20px" type="danger" @click="submit">提交充值</el-button>
            </div>
          </div>
        </el-dialog>
      </div>
      <!-- 弹窗 -->
      <el-dialog data-v-0c77fe6e :title="loadNoticeByPos.title" :visible.sync="dialogVisible" :modal-append-to-body="false" :lock-scroll="false" width="40%" custom-class="el-dialog1" :center="true" v-show="isshow" :modal="false">
        <div v-html="loadNoticeByPos.content"></div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="next" size="small">下次再说</el-button>
          <el-button data-v-652cfc00 type="primary" @click="mesg" size="small">我知道了</el-button>
        </span>
      </el-dialog>
      <!-- <dialog></dialog> -->
      <app-main />
    </div>
  </div>
</template>

<script>
import { timeConversion } from 'utils/utils'
import { moneyInfo, getSubTrad, getTopNotice, loadNoticeByPos, statusNum, inviteHttp, selectAll, deliverPrice, groupList, getsuperadsbytag, getAreaList, getAward } from 'network/api'
import AppMain from '../../../components/baseStructure/AppMain.vue'
import TopUp from '../../../components/TopUp.vue'
import Cookies from 'js-cookie'
export default {
  inject: ['reload'],
  components: {
    AppMain,
    TopUp
  },
  data () {
    return {
      o: '\u00a0\u00a0\u00a0\u00a0\u00a0',
      balance: '',
      // 公告
      notice: [],
      noticeTop: [],
      // 底部订单状态
      packInfo: {},
      // 邀请框
      url: '',
      language: '我发现一个电商人必备的礼品代发网站',
      people: '',
      arr: [],
      // 立即充值
      activeIndex: '0',
      dialogVisible: false,
      amountType: 'alipay',
      controls: false,
      num: undefined,
      gridData: [],
      dialogTableVisible: false,
      redialogVisible: false,
      imgUrl: '',
      input: '',
      balanceInfo: {},
      money: 0,
      styleObject: {
        color: 'red'
        // fontSize: '13px'
      },
      serverName: '',
      adslist: '',
      clickhref: '',
      groupData: '',
      awardName: null,
      awardUp: '',
      m: '000.00',
      loadNoticeByPos: [],
      isshow: true
    }
  },
  created () {
    getAward().then((res) => {
      if (res.code !== 1) return
      this.awardName = res.data
    })
    // 发件地址查询 // 得到发件人地址
    if (localStorage.getItem('token')) {
      getAreaList().then((res) => {
        if (res.code !== 1 && res.data.length === '') {
          return
        }
        if (res.code === 1 && res.data.length === 0) {
          return this.$confirm('亲,您还没有发件人地址不能提交订单, 是否继续?', '欢迎回来', {
            confirmButtonText: '马上添加发件人',
            cancelButtonText: '稍后提示我',
            type: 'success',
            center: true
          }).then((res) => {
            if (confirm) {
              this.$router.push('/orderAdmin/sendAddress')
            }
          })
        }
      })
    }
    // 顶部广告
    getsuperadsbytag({ tagname: 'purchase' }).then((res) => {
      if (res.code !== 1 && res.data.length === '') {
        return
      }
      this.adslist = res.data.adslist[0]
      this.clickhref = res.data.adslist[0].clickhref
    })
    // 余额
    this.getMoney()
    // 公告
    getTopNotice().then((res) => {
      this.noticeTop = res.data[0]
      this.notice = res.data
      this.notice.shift()
    })
    // 订单状态
    statusNum()
      .then((res) => {
        // console.log(res, '----')
        if (res.code !== 1 || undefined) {
          return false
        }
        if (res.code === 1) {
          this.packInfo = res.data
        }
      })
      .catch((err) => {
        console.log(err)
      })

    // 邀请链接
    inviteHttp().then((res) => {
      if (res.code !== 1 && res.data.length === '') {
        return
      }
      // eslint-disable-next-line camelcase
      const frontend_url = this.$store.getters.getSiteInfo.frontend_url
      // console.log(this.$store.getters.getUserInfo)
      // eslint-disable-next-line camelcase
      this.url = this.language + frontend_url + '/register?user_id=' + res.data
      // console.log(this.url)
    })
    // 邀请用户信息
    selectAll().then((res) => {
      if (res.code === 0) {
        // eslint-disable-next-line no-return-assign
        return (this.people = 0)
      }
      this.people = res.data.length
    })
  },
  computed: {
    // 注册时间戳转换
    createtime () {
      return timeConversion(this.$store.getters.getUserInfo.createtime)
    },
    compute () {
      return Object.keys(this.$store.getters.getSiteInfo.server_server)[0]
    },
    comput () {
      return Object.values(this.$store.getters.getSiteInfo.server_server)[0]
    }
  },
  methods: {
    setcookie () {
      const d = new Date()
      d.setTime(d.getTime() + 6 * 60 * 60 * 1000)// 24小时
      // d.setTime(d.getTime() + 1 * 60 * 60 * 1000);//1小时
      // d.setTime(d.getTime() + 1 * 60 * 1000);//1分钟
      // ad=popup-ad   键值对形式：name=key   expires 有效期
      document.cookie = 'ad=popup-ad;expires= ' + d.toGMTString()
      const result = document.cookie
      return result
    },
    dialog () {
      loadNoticeByPos({
        position: 'usercenter'
      }).then((res) => {
        this.loadNoticeByPos = res.data
        this.dialogVisible = true
      })
    },
    mesg () {
      this.dialogVisible = false
      window.localStorage.setItem('mesg', '我知道了')
    },
    next () {
      this.dialogVisible = false
      localStorage.removeItem('mesg')
      Cookies.remove('ad')
    },
    getMoney () {
      moneyInfo().then((res) => {
        // eslint-disable-next-line no-cond-assign
        this.balance = res.data.balance
        this.money = res.data.ok_money
      })
    },
    goUp () {
      this.$router.push('/account/balance')
    },
    // activeItem () {
    refresh () {
      // console.log('余额')
      // this.reload()
      // this.refresh()
      this.getMoney()
    },
    // setTimeout(function () {
    //   this.reload()
    // }, 1000)
    // },
    // 提交充值按钮
    submit () {
      getSubTrad({ trade: this.input }).then((res) => {
        if (res.code === 1) {
          this.$message({
            message: '充值成功',
            type: 'success'
          })
        } else {
          return this.$message.error({
            message: res.msg
          })
        }
      })
    },
    renovate () {
      moneyInfo().then((res) => {
        if (res.code === 1) {
          this.balanceInfo = res.data
          // console.log(res.data)
          this.$message({
            message: '刷新成功',
            type: 'success'
          })
        } else {
          this.$message.error({
            message: '刷新失败'
          })
        }
        // console.log(res)
      })
    },
    recharge () {
      this.redialogVisible = true
      this.imgUrl = this.$store.getters.getSiteInfo.alipay_paymentcode
    },
    // 广告点击跳转
    adsClick () {
      // window.open(this.clickhref)
    },
    // 复制网址成功
    onCopy (e) {
      this.$message.success('复制成功')
    },
    // 更换邀请语
    change () {
      this.arr = this.$store.getters.getSiteInfo.invite_title || ''
      const index = Math.floor(Math.random() * this.arr.length)
      this.language = this.arr[index]
      inviteHttp().then((res) => {
        // eslint-disable-next-line camelcase
        const frontend_url = this.$store.getters.getSiteInfo.frontend_url
        // eslint-disable-next-line camelcase
        this.url = this.language + ',' + frontend_url + '/register?user_id=' + res.data
      })
    },
    // 马上充值
    showRecharge () {
      this.dialogVisible = true
    },
    closePayDialog () { },
    load () {
      deliverPrice().then((res) => {
        if (res.code === 1) {
          this.gridData = res.data
          // for (let i = 0; i < res.length; i++) {
          // }
        }
        // console.log(res)
      })
      groupList().then((res) => {
        if (res.code === 1) {
          this.groupData = res.data
          // for (let i = 0; i < res.length; i++) {
          // }
        }
        // console.log(res)
      })
    },
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => { })
    }
  },
  mounted () {
    this.load()
    const vers = localStorage.getItem('mesg')
    if (!vers) {
      if (!document.cookie.includes('ad=')) {
        this.setcookie()
        this.dialog()
        this.isshow = true
      } else {
        this.isshow = false
      }
    }
  }
}
</script>

<style  lang="scss" scope>
.el-button--small,
[data-v-652cfc00] .el-button--small.is-round {
  padding: 9px 15px;
  width: 100px;
}
.itmup {
  position: relative;
  left: -280px;
  margin: 0 10px;
}
.awardUp {
  position: relative;

  // text-align: right;
  // display: block;
  // margin-left:2%;

  margin-top: 50px;
  // top: -30%;
  // font-size: 14px;
  // margin-top: 50px;
  // margin: 50 10px;
}
// ---
// .el-dialog__body {
//   padding: 30px 20px;
//   color: #606266;
//   font-size: 14px;
//   word-break: break-all;
//   // height: 600px;
//   max-height: 600px;
//   overflow-y: auto;
// }
.textLive1 {
  font-weight: 600;
  //  margin-left: 25%;
  margin-top: -20px;
  font-size: 18px;
  // text-align:center;
}
.textLive {
  font-weight: 600;
  color: #ee7e31;
  margin-left: 15%;
  padding-bottom: 20px;
  font-size: 18px;
  margin-top: -28px;
}
.groupData {
  margin-top: 30px;
  // position: relative;
  // text-align: center;
  float: left;
  margin-left: 12%;
  font-size: 16px;
  justify-content: space-between;
  //  position: inline-block;
  color: #1890ff;
}

.text.item {
  position: relative;
}
.el-icon-refresh {
  cursor: pointer;
  font-size: 20px;
  margin-left: 20px;
  color: #1890ff;
}
//顶部图片
.top_img-size {
  img {
    width: 100%;
    height: 80px;
  }
}
//顶部栏
.top_case {
  white-space: nowrap;
  display: flex;
  width: 100%;
  margin: {
    top: 20px;
    right: 20px;
  }
  &-one {
    margin-left: 20px;
    width: 30%;
  }
  &-two {
    width: 33%;
    .top_case-code {
      display: flex;
      flex-direction: column;
      .img {
        width: 90px;
        height: 90px;
        margin-top: -130px;
        margin-left: auto;
      }
      .img-text {
        text-align: right;
        color: gray;
      }
    }
  }
  &-three {
    margin-left: 15px;
    width: 33%;
    .notice-item-one {
      font-size: 18px;
      font-weight: 600;
      line-height: 50px;
      color: #db98f0;
      white-space: nowrap;
      max-height: 200px;
      overflow: auto;
      // padding-bottom: 11px;
      // display: flex;
      span {
        color: red;
        margin: 10px;
        padding-right: 15px;
      }
    }

    .notice-item {
      padding-bottom: 5px;
      display: flex;
    }

    img {
      width: 15px;
      height: 15px;
    }
  }
}

/* 中间栏 */
.center {
  // width: 100%;
  display: flex;
  margin-left: 20px;
}
.center-right {
  width: 33%;
  height: 100%;
  // margin: 16px;
  margin-top: 30px;
  margin-left: 30px;
  background-color: white;
  border-radius: 10px;
  .el-input__inner {
    margin: 8px 15px 0px 15px;
    width: 90%;
    height: 30px;
  }
  .btn {
    margin: 8px 15px 0px 15px;
  }
  .text {
    margin: 5px 15px 5px 15px;
  }
}
//渐变框
.liuc {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 63%;
  height: 88px;
  background: linear-gradient(308deg, #fda284, #f7d167);
  border-radius: 4px;
  box-sizing: border-box;
  margin: 25px 0;
  color: #fff;

  .items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 22px 0;
    flex: 1;
    cursor: pointer;
    transition: all 0.2s;

    .item_in {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-right: 1px solid hsla(0, 0%, 100%, 0.65);
      box-sizing: border-box;
      margin-top: 1px;
      p {
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.45);
        font-weight: bold;
      }
    }

    &:hover {
      cursor: pointer;
      background: #0002;
    }
  }

  img {
    // border-radius: 4px;
    width: 25px;
    height: 25px;
  }
}
//底部栏
.bottom {
  margin: 20px;
  width: 97%;
}
.bottom-content {
  height: 80px;
  background: #fff;
  box-shadow: 0 2px 20px rgba(15, 41, 131, 0.1);
  margin-bottom: 24px;
  padding: 32px 0;
  display: flex;
}
.bottom-item {
  color: gray;
  font-size: 14px;
  padding-left: 40px;
  width: 200px;
  border-right: 1px solid #f0f0f0;
  .font {
    color: black;
  }
  span {
    color: black;
    font-size: 28px;
  }
}
/* 卡片组件自带样式 */
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 10px;
}
.clearfix {
  display: flex;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/* 自定义卡片样式 */
.el-card__header {
  border-bottom: none;
}
.el-card__body {
  height: 190px;
  padding-top: 0px;
  padding-left: 40px;
}
.el-card,
.el-message {
  border-radius: 0px;
}
.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: none;
}
.el-card {
  border: none;
  // width: 540px;
}
.title-oblong {
  display: block;
  margin-right: 5px;
  color: #1a91ff;
  height: 20px;
  width: 6px;
  background-color: #1a91ff;
}
/* 充值Dialog */
.content-dialog {
  padding-left: 58px;
  padding-right: 58px;
}

.content-dialog .name {
  margin-bottom: 1em;
}

.content-dialog .price {
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}

.content-dialog .price .packing {
  display: flex;
}

.content-dialog .price .packing .packing-item {
  cursor: pointer;
  width: 80px;
  height: 32px;
  border: 1px solid #d9d9d9;
  margin-right: 16px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-dialog .price .packing .active {
  position: relative;
  border: 1px solid #1890ff;
}

.content-dialog .price .packing .packing-item.active .dagou {
  display: block;
}

.content-dialog .price .packing .packing-item .dagou {
  position: absolute;
  right: -1px;
  bottom: -1px;
  display: none;
}

.input-num {
  margin-left: 70px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.input-num .queding {
  color: #1890ff;
  cursor: pointer;
  padding: 0 8px;
}

.payway-btn {
  display: flex;
  align-items: center;
}

.payway-btn span {
  height: 20px;
}
.way {
  display: flex;
}

.payway {
  cursor: pointer;
  margin-right: 16px;
  width: 120px;
  height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  position: relative;
}

.payway .yue {
  margin: 0 8px;
}

.payway .dagou {
  display: none;
}

.payway.active {
  border: 1px solid #1890ff;
}

.payway.active .dagou {
  position: absolute;
  right: -1px;
  bottom: -1px;
  display: block;
}

.btn-pay {
  display: inline-block;
  background-color: #f93244;
  color: #fff;
  cursor: pointer;
  width: 120px;
  height: 32px;
  border-radius: 4px;
  line-height: 32px;
  text-align: center;
  margin-top: 24px;
}
</style>
<style scoped lang="scss">
$colors: #e8e8e8;
$colour: red;
@mixin public($colors) {
  color: $colors;
}
@mixin titles() {
  font: {
    size: 16px;
    weight: 600;
  }
}
.recharge-hint {
  border: 1px solid $colors;
  padding: 15px 15px 0 15px;
  div {
    padding-bottom: 15px;
    font-weight: 600;
    color: black;
    font: {
      size: 16px;
      weight: 600;
    }
  }
  p {
    padding-bottom: 10px;
    @include public(black);
  }
}
.recharge-title {
  margin-top: 15px;
  &-tag {
    @include titles();
  }
  &-text {
    @include titles();
  }
}
.recharge-main {
  display: flex;
  &-img {
    padding: 5% 10% 10% 10%;
    border-right: 1px solid $colors;
    img {
      width: 200px;
      height: 200px;
      border-radius: 10px;
      border: 5px solid #ee7e31;
    }
  }
  &-right {
    width: 100%;
    padding: 40px;
    .main-right-balance {
      padding: 10px 20px 10px 15px;
      background-color: #fff6f7;
      border: 1px solid $colour;
      border-radius: 10px;
      i {
        font-size: 30px;
        color: $colour;
      }
      p {
        color: $colour;
        font-weight: 600;
        margin-top: 10px;
      }
      .main-right-freshen {
        float: right;
        position: relative;
        top: -40px;
      }
    }
  }
}

.rechargeDialog .el-dialog__header,
.tixianDialog .el-dialog__header {
  border-bottom: 1px solid $colors;
}

.input-num .el-input-number--small {
  width: 180px;
}

.input-item.number .el-input-number {
  width: 324px;
}
</style>
<!--             <el-dialog :visible.sync="dialogTableVisible" data-v-2b8ec4 class="abow_dialog" ref="tabl" width="65%">
              <span class="textLive1">当前我的等级:
                <p class="textLive">{{ $store.getters.getUserInfo.group_text }}</p>
              </span>
              <el-table :data="gridData">
                <el-table-column label="" align="center">
                  <el-table-column v-for="(item, index) in gridData"   align="center" :key="index">
                  <span class="groupData"> {{item}}</span></el-table-column>
                   <span :class="[item.status == '0' ? 'orange' : '', item.status == '1' ? 'blue' : '', item.status == '2' ? 'green' : '', item.status == '3' ? 'green' : '', item.status == '4' ? 'green' : '']">{{ item.status | getStatus(item.status) }}</span>
                   <el-table-column v-for="(item, index) in groupData"   align="center" :key="index"><span class="groupData">{{item}}</span></el-table-column>
                   <el-table-column v-if="(item, index) in ">普通会员  至尊会员  金牌会员 gridData groupData
                  <el-table-column property="storage_name" label="仓库" align="center">
                    <template scope="scope">
                      <p>{{ scope.row.storage_name }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column property="price0" label="普通会员" align="center">
                    <template scope="scope">
                      <p v-if="$store.getters.getUserInfo.group_text == '普通会员'" style="color: red">{{ scope.row.price0 }}</p>
                      <p v-else>{{ scope.row.price0 }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column property="price1" label="金牌会员" align="center">
                    <template scope="scope">
                      <p v-if="$store.getters.getUserInfo.group_text == '金牌会员'" style="color: red">{{ scope.row.price1 }}</p>
                      <p v-else>{{ scope.row.price1 }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column property="price2" label="至尊会员" align="center">
                    <template scope="scope">
                      <p v-if="$store.getters.getUserInfo.group_text == '至尊会员'" style="color: red">{{ scope.row.price2 }}</p>
                      <p v-else>{{ scope.row.price2 }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column property="price3" label="大客户" align="center">
                    <template scope="scope">
                      <p v-if="$store.getters.getUserInfo.group_text == '大客户'" style="color: red">{{ scope.row.price3 }}</p>
                      <p v-else>{{ scope.row.price3 }}</p>
                    </template>
                  </el-table-column>
                </el-table-column>
              </el-table>
            </el-dialog> -->
